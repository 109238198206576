<template>
  <div>
    <v-autocomplete
      v-model="selected"
      :items="$store.state.common.cities"
      item-value="id"
      item-text="name"
      label="Su ciudad de residencia"
      :rules="[(v) => !!v || 'Campo obligatorio']"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: null
    };
  },
  props: {
    countryId: {
      type: String,
      required: true
    }
  },
  async created() {
    await this.$store.dispatch("common/getCities", { filter: { country: this.countryId } });
  },
  watch: {
    selected(val, old) {
      if (val && val !== old) this.$emit("selected", val.id ?? val);
    },
    async countryId() {
      await this.$store.dispatch("common/getCities", { filter: { country: this.countryId } });
    }
  }
};
</script>
