<template>
  <v-container>
    <BoxTitle :title="'Información personal'">
      <template v-slot:content>
        Para empezar, <b>necesitamos su información personal</b>. Es importante que rellene todo en esta parte: así podremos guardar su
        solicitud y podrá volver a rellenarla en cualquier momento.
      </template>
    </BoxTitle>
    <v-form ref="formNewBorrower" v-model="formNewBorrowerValid" lazy-validation @submit.prevent="">
      <BoxField :title="'NOMBRE'">
        <template v-slot:content>
          <v-text-field v-model="borrower.first_name" label="Su nombre" :rules="formRules.requiredField" />
        </template>
      </BoxField>
      <BoxField :title="'APELLIDOS'">
        <template v-slot:content>
          <v-text-field v-model="borrower.last_name" label="Sus apellidos" :rules="formRules.requiredField" />
        </template>
      </BoxField>
      <BoxField :title="'DOCUMENTO DE IDENTIDAD'" :readonly="newBorrowerDocumentIdentity ? true : false">
        <template v-slot:content>
          <v-text-field
            v-model="borrower.identity_document"
            label="Número documento de identidad"
            :rules="formRules.requiredField"
            :readonly="newBorrowerDocumentIdentity ? true : false"
          />
        </template>
      </BoxField>
      <BoxField :title="'EMAIL'">
        <template v-slot:content>
          <v-text-field v-model="borrower.email" type="email" label="Su correo electrónico" :rules="formRules.emailRequiredRules" />
        </template>
      </BoxField>
      <BoxField :title="'PHONE'">
        <template v-slot:content>
          <v-text-field v-model="borrower.phone" label="Nº de teléfono" :rules="formRules.phoneRules" />
        </template>
      </BoxField>
      <BoxField :title="'DIRECCIÓN'">
        <template v-slot:content>
          <v-text-field v-model="borrower.address.name" label="Dirección del domicilio" :rules="formRules.requiredField" />
        </template>
      </BoxField>
      <BoxField :title="'PAÍS'">
        <template v-slot:content>
          <CountryAutocomplete @selected="borrower.country = $event" />
        </template>
      </BoxField>
      <BoxField v-if="!_.isEmpty(borrower.country)" :title="'CIUDAD'">
        <template v-slot:content>
          <CityAutocomplete :countryId="borrower.country" @selected="borrower.city = $event" />
        </template>
      </BoxField>
      <BoxField v-if="!_.isEmpty(borrower.city)" :title="'ZONA/POBLACIÓN/COMUNIDAD'">
        <template v-slot:content>
          <v-text-field v-model="borrower.zone" label="Su zona de residencia" :rules="formRules.requiredField" />
        </template>
      </BoxField>
      <BoxField :title="'FECHA DE NACIMIENTO'">
        <template v-slot:content>
          <DatePicker label="Fecha de nacimiento" :type_birthdate="true" :required="true" @change="setDateOfBirth" />
        </template>
      </BoxField>
    </v-form>
    <div class="text-center">
      <v-btn class="mt-5 mt-md-0 primary normal-button" rounded @click="createBorrower()">
        <span class="font-weight-bold">Guardar y enviar</span>
      </v-btn>
    </div>

    <pre>{{ borrower }}</pre>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import _ from "lodash";
import DatePicker from "@/components/common/DatePicker.vue";
import BoxTitle from "@/components/common/BoxTitle.vue";
import BoxField from "@/components/common/BoxField.vue";
import CountryAutocomplete from "@/components/common/CountryAutocomplete.vue";
import CityAutocomplete from "@/components/common/CityAutocomplete.vue";

export default {
  name: "NewBorrower",
  components: { DatePicker, BoxTitle, BoxField, CountryAutocomplete, CityAutocomplete },
  mixins: [FormRulesMixin, FormatDateMixin],
  data() {
    return {
      formNewBorrowerValid: false,
      borrower: {
        first_name: "",
        last_name: "",
        identity_document: "",
        email: "",
        phone: "",
        address: {
          coordinates: {
            lat: 0,
            lon: 0
          },
          name: ""
        },
        country: "",
        city: "",
        zone: "",
        date_of_birth: ""
      }
    };
  },
  computed: {
    ...mapState("borrower", ["newBorrowerDocumentIdentity"]),
    _() {
      return _;
    }
  },
  async created() {
    this.borrower.identity_document = this.newBorrowerDocumentIdentity !== "" ? this.newBorrowerDocumentIdentity : "";
  },
  methods: {
    async createBorrower() {
      if (this.$refs.formNewBorrower.validate()) {
        const borrowerFormattedData = { ...this.borrower };
        borrowerFormattedData.date_of_birth = this.formatDateToISO8601(this.borrower.date_of_birth);

        const response = await this.$store.dispatch("borrower/createBorrower", borrowerFormattedData);
        if (response.success) {
          await this.$store.dispatch("borrower/checkBorrowerExists", this.borrower.identity_document);
          this.$router.push("/select-office");
        }
      }
    },

    setDateOfBirth(value) {
      this.borrower.date_of_birth = value;
    }
  }
};
</script>
