<template>
  <div>
    <v-autocomplete
      v-model="selected"
      :items="$store.state.common.countries"
      item-value="id"
      item-text="name"
      label="Su país de residencia"
      :rules="[(v) => !!v || 'Campo obligatorio']"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: null
    };
  },
  async created() {
    await this.$store.dispatch("common/getCountries");
  },
  watch: {
    selected(val, old) {
      if (val && val !== old) this.$emit("selected", val.id ?? val);
    }
  }
};
</script>
